var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.tooltip.disabled ? _c('span', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip,
      expression: "tooltip"
    }],
    staticClass: "button-wrapper"
  }, [_c('button', {
    staticClass: "button",
    class: {
      'button_loading': _vm.loading
    },
    attrs: {
      "disabled": _vm.disabled || _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")])]) : _c('button', {
    staticClass: "button",
    class: {
      'button_loading': _vm.loading
    },
    attrs: {
      "disabled": _vm.disabled || _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]);
}
var staticRenderFns = []

export { render, staticRenderFns }