//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BtnElement',
  props: {
    loading: {
      type: Boolean,
      required: false
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    },
    reasons: {
      type: [String, Array],
      default: ''
    }
  },
  computed: {
    tooltip() {
      return {
        content: typeof this.reasons === 'string' ? this.reasons : this.reasons.join('<br>'),
        disabled: !this.disabled || !this.reasons.length || this.loading,
        html: true
      }
    }
  }
}
