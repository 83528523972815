var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainSidebar-center"
  }, [_c('transition', {
    attrs: {
      "name": "appear",
      "appear": ""
    }
  }, [_c('form', {
    staticClass: "login-form",
    attrs: {
      "method": "post"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.send.apply(null, arguments);
      }
    }
  }, [_c('h1', {
    staticClass: "login-form__title"
  }, [_vm._v("\n        Вход\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "login-form__field",
    class: {
      'login-form__field_error': _vm.hasError
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.login,
      expression: "login"
    }],
    ref: "loginField",
    staticClass: "login-form__input",
    attrs: {
      "type": "text",
      "name": "username",
      "placeholder": "Логин или email",
      "required": "",
      "autocomplete": "username"
    },
    domProps: {
      "value": _vm.login
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.login = $event.target.value;
      }, _vm.resetError]
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "login-form__field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "login-form__input",
    attrs: {
      "type": "password",
      "name": "password",
      "required": "",
      "placeholder": "Пароль",
      "autocomplete": "current-password"
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.password = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "login-form__error"
  })]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "login-form__restore-button",
    attrs: {
      "to": "/restore"
    }
  }, [_vm._v("\n        восстановить\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "login-form__submit-block"
  }, [_c('div', {
    staticClass: "login-form__submit-buttons"
  }, [_c('Btn', {
    staticClass: "login-form__submit-button",
    attrs: {
      "loading": _vm.sending,
      "label": "Войти"
    }
  }), _vm._v(" "), _c('a', {
    staticClass: "login-form__social-button login-form__social-button_vk",
    class: {
      'login-form__social-button_loading': _vm.socialSending
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.loginVk.apply(null, arguments);
      }
    }
  })], 1)])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "registration-link-box"
  }, [_vm._v("\n    Ещё нет аккаунта? "), _c('NuxtLink', {
    staticClass: "registration-link-box__link ",
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("\n      Зарегистрируйтесь\n    ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }