//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Btn from "components/btn";
import getMeta from "@/utils/getMeta"
export default {
  name: "LoginPage",
  components: { Btn },
  data() {
    return {
      hasError: false,
      sending: false,
      socialSending: false,
      login: '',
      password: '',
    }
  },
  head() {
    return getMeta({
      title: 'Вход на сайт буккроссинга',
      description: 'Укажите свой логин и пароль, чтобы посмотреть свои книги, зарегистрировать, отметить как пойманные и отпущенные'
    })
  },
  beforeMount() {
    if (this.$auth.loggedIn) {
      this.$router.replace('/')
    }
  },
  mounted() {
    window.addEventListener('message', this.vkCodeListener)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.vkCodeListener)
  },
  methods: {
    async vkCodeListener(e) {
      const code = e.data?.code;
      if (!code) {
        return
      }

      const response = await this.$axios.$post(
        '/users/login/vk',
        {
          code
        }
      );
      this.socialSending = false;
      this.$auth.setUser(response.data)
      this.$auth.setUserToken(response.data?.token)
      await this.$router.replace('/')
    },
    async send() {
      const {$auth, login, password} = this

      if (!login.length) {
        this.hasError = true;
        return;
      }

      this.sending = true
      try {
        await $auth.loginWith('local', {
          data: {login, password}
        });
        await this.$router.replace('/')
        this.$toast.success('Вы успешно авторизовались')
      } catch {
        this.failedLogin()
      }
      this.sending = false
    },
    async loginVk() {
      this.socialSending = true;
      const response = await this.$axios.$get('/users/login/vk');
      setTimeout(() => {
        window.open(
          response.data.url,
          'auth',
          'toolbar=no,location=no,width=655,height=350'
        );
      })
    },
    failedLogin() {
      this.$toast.error('Неправильный логин или пароль');
      this.password = ''
      this.error = true
      this.$refs.loginField.focus()
    },
    resetError() {
      this.error = false
    }
  }
}
